<template>
  <div class="mb-3 mt-5 pt-3">
    <RegisterConfirmationOtp
      ref="otpRegister"
      :error-message="errorMessage"
      :is-start-countdown="startCountdown"
      :timestamp-expired="timestampExpired"
      :otp="otp"
      @setOtp="setOtp"
      @validateOtp="validateOtp"
      @resendOtp="resendOtp"
    />
  </div>
</template>

<script>
import RegisterConfirmationOtp from '@/components/cleanui/system/Auth/RegConfOtp'
import Cookies from 'js-cookie'
import { inviteUserVerification } from '@/api/user'

export default {
  components: {
    RegisterConfirmationOtp,
  },
  data () {
    return {
      otp: '',
      errorMessage: null,
      startCountdown: false,
      timestampExpired: 0,
      uuid: null,
      userData: {},
      idInvited: null,
    }
  },
  created() {
    const userData = Cookies.get('dataUserInvited') ? JSON.parse(Cookies.get('dataUserInvited')) : {}
    if (userData) {
      const now = this.$moment(userData.expired_at).format()
      const then = this.$moment().format()

      const diff = this.$moment.duration(this.$moment(now).diff(this.$moment(then)))

      const days = parseInt(diff.asDays())

      let hours = parseInt(diff.asHours()) // but it gives total hours in given miliseconds which is not expacted.

      hours = hours - days * 24

      let minutes = parseInt(diff.asMinutes()) // but it gives total minutes in given miliseconds which is not expacted.

      minutes = minutes - (days * 24 * 60 + hours * 60)

      let total

      if (hours || minutes) {
        total = ((hours * 60) + minutes) * 60 // to seconds
      } else {
        total = 0
      }

      this.uuid = userData.uuid
      this.timestampExpired = total
      this.startCountdown = true
      this.userData = userData
      this.idInvited = userData.id
    }
  },
  methods: {
    setOtp (value) {
      this.otp = value
    },
    async validateOtp () {
      // call submit otp nya 
      await inviteUserVerification({
        id: this.idInvited,
        token: this.otp,
        password: this.userData.password,
      })
      .then(() => {
        Cookies.remove('dataUserInvited')
        this.$notification.success({
          message: 'Register Success',
        })
        this.$router.push({
          path: '/auth/register/success',
        })
      })
      .catch((err) => {
        this.$notification.error({
          message: err,
        })
      })
    },
    resendOtp () {
      // please call resend otp based on user registered.
      // is from email or from phone, or another user getting otp
    },
  },
}
</script>

<style>

</style>
